
import { Options, Vue } from "vue-class-component";
import qs from "qs";
import moment from "moment";
import { Notify, Toast } from "vant";
import { Sleep } from "../../middlewares/common";
import { envTypes, SampleModes } from "../../middlewares/enum";

export default class SpecimenInfo extends Vue {
  private show = false;
  private sampleModes = SampleModes;
  private goodForm = {
    idx: null, //序号：编辑时使用
    // envType: [], //环境类型
    // otherType: "", //其他类型
    // specObj: "", //采集对象
    specName: "", //样本名称
  };
  private specimenForm = {};
  private applyForm = {
    sampleList: [],
  };
  private sepcimenModuleList = [];
  private showSpecimenInfo(item: any) {
    this.getApplicationById(item.applicationId);
    this.getModuleListBySpecimenId(item.id);
    this.specimenForm = item;
    this.show = true;
  }

  private getApplicationById(id: any) {
    const url = "/api/lims/application/getApplyById";
    this.axios
      .get(url, { params: { id } })
      .then(async (res: any) => {
        if (res.status.code !== 1) {
          //this.$alert(res.status.msg, '提示');
        } else {
          const data = res.data;
          this.applyForm = data;
        }
      })
      .catch((err) => {
        //this.$alert(err, '提示');
      });
  }

  //根据样本id获取项目
  private getModuleListBySpecimenId(specId: any) {
    this.sepcimenModuleList = [];
    const url = "/api/lims/sampleSpecimen/getModuleListBySpecimenId";
    this.axios
      .get(url, { params: { id: specId } })
      .then((res: any) => {
        if (res.status.code !== 1) {
          //this.$alert(res.status.msg, '提示');
        } else {
          this.sepcimenModuleList = res.data.moduleList;
        }
      })
      .catch((err) => {
        //this.$alert(err, '提示');
      });
  }

  private formatDateToDays(time: any) {
    if (time) {
      return moment(time).format("YYYY-MM-DD");
    } else {
      return "";
    }
  }
  private getSampleModeDesc(scope: any) {
    for (const item of this.sampleModes) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private submitForm() {
    const sltSpecimenList: any = [];
    sltSpecimenList.push(this.specimenForm);

    this.$dialog
      .confirm({
        title: "确认",
        message: "确认要接收样本么？",
      })
      .then(() => {
        const obj: any = {
          specimenList: sltSpecimenList,
        };
        obj.receiptTime = moment().format("YYYY-MM-DD");
        const url =
          "/api/lims/applicationSampleSpecimenApproval/batchAddApplicationSampleSpecimenApproval";
        this.axios
          .post(url, obj)
          .then((res: any) => {
            if (res.status.code === 1) {
              Toast("接收成功");
              this.$emit("doSearch");
              this.show = false;
            } else {
              Notify(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(() => {
        console.log("cancel delete");
      });
  }

  private cancelForm() {
    this.goodForm = {
      idx: null, //序号：编辑时使用
      //   envType: [], //环境类型
      //   otherType: "", //其他类型
      //   specObj: "", //采集对象
      specName: "", //样本名称
    };
    this.show = false;
  }
}
