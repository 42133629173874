
import { Options, Vue } from "vue-class-component";
import { SampleSpecimenStatus } from "@/middlewares/enum";
import SpecimenInfo from "./SpecimenInfo.vue";
import { Notify, Toast } from "vant";
import moment from "moment";
@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
    SpecimenInfo,
  },
})
export default class NoRecSpecimenList extends Vue {
  private sampleSpecimenStatus = SampleSpecimenStatus;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private specimenList: any = [];
  private sltSpecimenList: any = [];
  private pageIndex = 1;
  private allChecked = false;
  private searchDateType = "today";
  private searchForm: any = {
    code: "", //采集编号
    name: "", //采集居民
    startDate: moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };
  private findCovidCollectList() {
    if (this.refreshing) {
      this.specimenList = [];
      this.refreshing = false;
    }
    this.loading = true;
    const url = "/api/lims/sampleSpecimen/getSpecimenByCode";
    this.axios
      .get(url, {
        params: {
          code: this.searchForm.code,
          orgId: this.$store.state.userInfo.orgId,
          pageSize: 20,
          pageIndex: this.pageIndex,
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          for (const record of res.data.records) {
            record.checked = false;
          }
          this.specimenList = this.specimenList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.specimenList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private submitForm() {
    this.sltSpecimenList = [];
    for (const sp of this.specimenList) {
      const sb: any = sp;
      if (sb.checked) {
        this.sltSpecimenList.push(sb);
      }
    }
    if (this.sltSpecimenList.length === 0) {
      Toast("请选择需要接收的样本");
      return;
    }

    this.$dialog
      .confirm({
        title: "确认",
        message: "确认要接收样本么？",
      })
      .then(() => {
        const obj: any = {
          specimenList: this.sltSpecimenList,
        };
        obj.receiptTime = moment().format("YYYY-MM-DD");
        const url =
          "/api/lims/applicationSampleSpecimenApproval/batchAddApplicationSampleSpecimenApproval";
        this.axios
          .post(url, obj)
          .then((res: any) => {
            if (res.status.code === 1) {
              Toast("接收成功");
              this.sltSpecimenList = [];
              this.allChecked = false;
              this.doSearch();
            } else {
              Notify(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(() => {
        console.log("cancel delete");
      });
  }

  private doSearch() {
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  // 格式化检测状态
  //获取样本状态字典
  private getSampleSpecimenStatusDesc(scope: any) {
    for (const item of this.sampleSpecimenStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private formatDataTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }
  private checkAllChange(event: MouseEvent) {
    for (const sp of this.specimenList) {
      sp.checked = this.allChecked;
    }
  }
  private checkOneChange(event: MouseEvent) {
    let isAllCheck = true;
    for (const sp of this.specimenList) {
      if (!sp.checked) {
        isAllCheck = false;
        break;
      }
    }
    this.allChecked = isAllCheck;
  }

  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findCovidCollectList();
  }
  //重置查询
  private resetSearch() {
    this.searchDateType = "today";
    this.searchForm = {
      code: "", //采集编号
      name: "", //采集居民
      startDate: moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }

  private showSpecimen(item: any) {
    //打开样本详情
    const temp: any = this.$refs.specimenInfoRef;
    temp.showSpecimenInfo(item);
  }
}
